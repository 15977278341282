import React from "react";
import { DayPicker } from "react-dates";
import { connect } from "react-redux";
import moment from "moment";
import {
  Container,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
  Statistic,
  Label,
  Popup,
  Card,
  Divider,
  Dimmer
} from "semantic-ui-react";
import {
  startGetTrHours
} from "../../store/actions/core/timereport/hour";
import { PieChart, Pie, Cell } from 'recharts';
import { startGetTRHolidays } from "../../store/actions/core/administration/holiday";
import { pages } from "../../assets/resources/apps_permissions";
import Can from "../../abilities/Can";
import NavLink from "react-router-dom/NavLink";
import { withRouter } from 'react-router-dom'

require("moment/locale/en-gb");

class HomeContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
      selectedDates: new Set(),
      currentCN: props.cn,
      currentUserID: props.user_id,
      user_groups: props.user_groups,
      getEvents: this.getHours,
      percentageTR: null,
      is_dummy: 0,
      name: props.name,
      datesStatus: {},
      apps: pages
    }
  }

  componentDidMount() {
    if (this.state.currentCN) {
      //console.log("Did we ever enter here? ")
      this.state.getEvents();
    }
  }

  //TODO: refactoring componentWillReceiveProps with getDerivedStateFromProps whenever we want to upgrade React version
  /*static getDerivedStateFromProps(nextProps, prevState)
  {
      //console.log(nextProps, prevState);
      let update = {};
      if (nextProps.hours !== prevState.events) {
          update.events = nextProps.hours;
      }
      if (nextProps.cn !== prevState.currentCN) {
          update.currentCN = nextProps.cn;
          update.name = nextProps.name;
          update.currentUserID = nextProps.user_id;
          update.user_groups = nextProps.user_groups;
      }

      //console.log("WHAT AM I DOING? ", update);
      return update;
  }*/


  componentWillReceiveProps(nextProps) {

    let events = nextProps.hours;
    let holidays = nextProps.tr_holidays;
    if (holidays && holidays.length > 0) events = events.concat(holidays);

    // calculate for each day if all hours have been inserted (i.e. at least 8 hours)
    let datesStatus = events.reduce((dates, event) => {
      if (moment(event.date).day() === 0 || moment(event.date).day() === 6) {
        dates[event.date] = (dates[event.date] || 0) + event.hour;
      } else {
        if (event.hour_type_code === "NORM")
          dates[event.date] = (dates[event.date] || 0) + event.hour;
        else
          dates[event.date] = (dates[event.date] || 0) + 0;
      }
      return dates;
    }, {});
    //console.log("all hours have been inserted: ", datesStatus);

    //Object.keys(datesStatus).map((date) => datesStatus[date] = (datesStatus[date] >= 8));

    let cn = this.state.currentCN;
    let userID = this.state.currentUserID;
    let user_groups = this.state.user_groups;
    let name = this.state.name;
    //console.log("user_groups ", user_groups, " name ", name);

    //calc percentage of timereport
    let date = new Date(this.state.date);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let size = 0;
    let datesStatusPerc = Object.assign({}, datesStatus);
    Object.keys(datesStatusPerc).map((date) => datesStatusPerc[date] = (datesStatusPerc[date] >= 8));
    Object.values(datesStatusPerc).map((date) => {
      if (date === true) {
        return ++size
      }
      return null;
    });
    const percentageTR = Math.round(size * 100 / lastDay);
    //console.log("Percentuale timereport: ", percentageTR, 100-percentageTR);

    this.setState({
      datesStatus,
      percentageTR,
      currentCN: !cn ? nextProps.cn : cn,
      currentUserID: !userID ? nextProps.user_id : userID,
      user_groups: !user_groups ? nextProps.user_groups : user_groups,
      name: !name ? nextProps.name : name,
    }, () => {
      if ((!cn && nextProps.cn)) {
        this.state.getEvents();
      }
    });
  }

  /**
   * Fetch Hours events from API for Calendar, but filtered with current year and month
   */
  getHours = () => {
    const params = {
      year: this.state.date.year(),
      month: this.state.date.month() + 1,
      is_dummy: this.state.is_dummy
    };
    this.props.getHours(this.state.currentCN, params);
    this.props.getHolidays(params, this.state.currentUserID);
  };


  renderDayContents = (date) => {

    const { datesStatus } = this.state;
    //console.log("DATEEEEES ", {datesStatus}, this.props.company_code);
    if (!datesStatus) return "";
    if (datesStatus[date.format("YYYY-MM-DD")] === 8) {
      return <span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="teal" name="check" /></span>;
    } else if (datesStatus[date.format("YYYY-MM-DD")] > 8 && this.props.company_code === "TESPA") {
      return <div><Popup content="More than 8 hours!" trigger={<span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="orange" name="exclamation triangle" /></span>} /></div>;
    } else {
      return (
        <span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="red" name="cancel" /></span>
      );
    }
  };

  /**
   * Move to next month both mini calendar and main Calendar
   */
  onNextMonthClick = () => {
    this.setState((state) => ({
      date: moment(state.date).add(1, "M"),
      selectedDates: new Set()
    }), this.state.getEvents);
  };

  /**
   * Move to previous month both mini calendar and main Calendar
   */
  onPrevMonthClick = () => {
    this.setState((state) => ({
      date: moment(state.date).subtract(1, "M"),
      selectedDates: new Set(),
    }), this.state.getEvents);
  };


  render() {

    const data = [{ name: 'Complete percentage', value: this.state.percentageTR }, {
      name: 'Hour to add',
      value: 100 - this.state.percentageTR
    }];
    //console.log("data visible in graph: ", data);
    const COLORS = ['#00b5ad', 'gray'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {(this.state.percentageTR !== 0 && this.state.percentageTR !== 100) ? `${(percent * 100).toFixed(0)}%` : ""}
        </text>
      );
    };

    const { pathname } = this.props.location;
    //console.log(pathname)
    //console.log(this.state);

    return (
      <Container>
        <Segment>
          <Header as="h1">Hi {this.state.name}!</Header>
          <Segment secondary>
            <Dimmer inverted active={!this.props.abilities || this.props.abilities.length === 0 || this.state.percentageTR === null || this.props.loading_hours || this.props.loading_tr}>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Header as='h2' style={{ display: "inline", marginRight: "1rem" }}>Recap Time Report</Header>
            <Popup
              content={"Here you can find the recap of your monthly time report. For any modification, please go to related application."}
              trigger={(
                <Label style={{ verticalAlign: "middle" }} color="teal" circular>
                  ?
                </Label>
              )}
            />
            <Grid columns={3}>
              <Grid.Row>
                <Grid.Column verticalAlign="middle" textAlign="center">
                  <PieChart className="radialBarHome" width={600} height={350}>
                    <Pie isAnimationActive={false} dataKey="value" data={data} cx={300} cy={200}
                      labelLine={false} label={renderCustomizedLabel} outerRadius={90}
                      fill="#8884d8">
                      {
                        data.map((entry, index) => <Cell key={index}
                          fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                  </PieChart>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center">
                  <Statistic fluid="true">
                    <Statistic.Value>{(this.state.percentageTR || 0) + '%'}</Statistic.Value>
                    <Statistic.Label>Completed Percentage</Statistic.Label>
                  </Statistic>

                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center">
                  {!this.props.loading_hours && !this.props.loading_tr ?
                    <DayPicker numberOfMonths={1}
                      hideKeyboardShortcutsPanel={true}
                      renderDayContents={this.renderDayContents}
                      initialVisibleMonth={() => this.state.date}
                      onNextMonthClick={this.onNextMonthClick}
                      onPrevMonthClick={this.onPrevMonthClick}

                    />
                    : ""
                  }

                </Grid.Column>

              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            <Grid>
              <Grid.Row style={{ marginBottom: "2rem", marginTop: "2rem", marginLeft: "8rem", marginRight: "8rem" }}>
                <Card.Group style={{ justifyContent: "center" }}>
                  {pages.map((item) => (
                    item.title !== "Home" &&
                    <Can I={item.action} a={item.subject} key={item.link}>
                      {pathname === "/project-planning/PFE_next/:pfe_id" ?
                        <Card as={NavLink} className='homeCards' onClick={() => {
                          if (false) {
                            this.props.navigate(item.link)
                          } else {
                            // show modal
                          }
                        }}>
                          <Card.Content>
                            <Card.Header><Icon name={item.icon} />{" " + item.title}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content description={item.description} />
                        </Card>
                        :
                        <Card as={NavLink} to={item.link} className='homeCards'>
                          <Card.Content>
                            <Card.Header><Icon name={item.icon} />{" " + item.title}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content description={item.description} />
                        </Card>
                      }
                    </Can>))}
                </Card.Group>
              </Grid.Row>
              <Divider horizontal>Useful links</Divider>
              <Grid.Row style={{ marginBottom: "5rem", marginTop: "2rem", marginLeft: "8rem", marginRight: "8rem" }}>
                <Card.Group centered>
                  <Card href="https://sites.google.com/techedgegroup.com/edgenet/home"
                    className='homeCardsSpecial' target="_blank">
                    <Card.Content>
                      <Card.Header><Icon name="book" /> Intranet Portal</Card.Header>
                    </Card.Content>
                    <Card.Content description="Avvale Intranet Portal" />
                  </Card>

                  {/* Rimozione Card - no ticket */}
                  {/* <Card href="https://portfolio.avvale.com"
                    className='homeCardsSpecial' target="_blank">
                    <Card.Content>
                      <Card.Header><Icon name="lab" />Solutions Portfolio</Card.Header>
                    </Card.Content>
                    <Card.Content description="Our value propositions portfolio" />
                  </Card> */}

                  {/* <Card href="https://reference.avvale.com"
                    className='homeCardsSpecial' target="_blank">
                    <Card.Content>
                      <Card.Header><Icon name="student" />Customer Stories</Card.Header>
                    </Card.Content>
                    <Card.Content description="Our gallery of successes" />
                  </Card> */}
                  {/* Rimozione Card - no ticket */}

                  {/* TEP-72 - Creation of the Support Portal link */}
                  {/* AEP-2730 - Nuovo link */}
                  <Card href="https://avvale-support.atlassian.net/wiki/spaces/AVVALEAEP/pages/19595414/AEP+-+Knowledge+Base"
                    className='homeCardsSpecial' target="_blank">
                    <Card.Content>
                      <Card.Header><Icon name="info" />{" AEP Support Portal"}</Card.Header>
                    </Card.Content>
                    <Card.Content description="AEP Guides & FAQs" />
                  </Card>

                  {/*this.props.company_code !== "TEPRE" ?
                                        <Card href="https://hcmcloud.talentiasw.com/hrtechedge"
                                            className='homeCardsSpecial' target="_blank">
                                            <Card.Content>
                                                <Card.Header><Icon name="like" />{" Talentia"}</Card.Header>
                                            </Card.Content>
                                            <Card.Content description="Techedge Talentia" />
                                        </Card> : ""*/}
                  {this.props.company_code === "TESPA" ?
                    <Card href="https://www.sipert.it.adp.com/js/js2config/bh.revit.index.jsp"
                      className='homeCardsSpecial' target="_blank">
                      <Card.Content>
                        <Card.Header><Icon name="money bill" />{" ADP"}</Card.Header>
                      </Card.Content>
                      <Card.Content description="Payroll portal" />
                    </Card> : ""}
                  {this.props.company_code === "TESPA" || this.props.company_code === "NMBIT" ||
                    this.props.company_code === "ESGEO" || this.props.company_code === "STURN" ||
                    this.props.company_code === "TEARD" || this.props.company_code === "NEWLO" ||
                    this.props.company_code === "GURMX" ? <Card href="https://sites.google.com/techedgegroup.com/forms-procurement/home"
                      className='homeCardsSpecial' target="_blank">
                      <Card.Content>
                        <Card.Header><Icon name="shopping cart" />{" Procurement"}</Card.Header>
                      </Card.Content>
                      <Card.Content description="Link Forms" />
                    </Card> : ''}
                  {this.props.company_code === "TESPA" || this.props.company_code === "TEPRE" ?
                    <Card href="https://beneficiari.edenred.it/"
                      className='homeCardsSpecial' target="_blank">
                      <Card.Content>
                        <Card.Header><Icon name="ticket" />{" Ticket Restaurant"}</Card.Header>
                      </Card.Content>
                      <Card.Content description="Beneficiari Edenred Portal" />
                    </Card> : ""}
                  {this.props.company_code === "TESPA" || this.props.company_code === "TEPRE" ?
                    <Card href="http://www.fondoest.it/"
                      className='homeCardsSpecial' target="_blank">
                      <Card.Content>
                        <Card.Header><Icon name="doctor" />{" Fondo Est"}</Card.Header>
                      </Card.Content>
                      <Card.Content description="Health Care Services" />
                    </Card>
                    : ""}

                  {/* Remove TEP Training Material*/}
                  {/* <Card href="https://drive.google.com/drive/folders/1-06bq0x4uA9uUH7cglwju3PkDYQogTsP"
                                        className='homeCardsSpecial' target="_blank">
                                        <Card.Content>
                                            <Card.Header><Icon name="folder open" />TEP Training Material</Card.Header>
                                        </Card.Content>
                                        <Card.Content description="TEP Training Material" />
                                    </Card> */}
                  <Card href="https://www.avvale.com"
                    className='homeCardsSpecial' target="_blank">
                    <Card.Content>
                      <Card.Header><Icon name="world" />{" Avvale Website"}</Card.Header>
                    </Card.Content>
                    <Card.Content description="About Avvale" />
                  </Card>

                  {/* //good night, sweet prince 
                                    <Card href="https://sites.google.com/techedgegroup.com/wiki-tep/home"
                                          className='homeCardsSpecial' target="_blank">
                                        <Card.Content>
                                            <Card.Header><Icon name="folder open" />Techedge Portal Wiki</Card.Header>
                                        </Card.Content>
                                        <Card.Content description="Techedge Portal Wiki" />
                                    </Card>    
                                    */}
                </Card.Group>
              </Grid.Row>

              {/* TEP-66
                            <Divider horizontal>TRC application</Divider>
                            <Grid.Row centered style={{ marginBottom: "5rem", marginTop: "2rem", marginLeft: "8rem", marginRight: "8rem" }}>
                                <Card.Group centered style={{textAlign: "left"}}>
                                    <Card href="https://techedge-portal-trc-app.s3-eu-west-1.amazonaws.com/android/latest/trc-220920-PROD.apk" className='homeCardsSpecial' target="_blank">
                                        <Card.Content>
                                            <Card.Header><Icon name="android" />{" Techedge Android App"}</Card.Header>
                                        </Card.Content>
                                        <Card.Content description="Download the Android application" />
                                    </Card>

                                    <Card href="itms-services://?action=download-manifest&url=https://techedge-portal-trc-app.s3-eu-west-1.amazonaws.com/ios/latest/manifest.plist" className='homeCardsSpecial' target="_blank">
                                        <Card.Content>
                                            <Card.Header><Icon name="apple" />{" Techedge iOS App"}</Card.Header>
                                        </Card.Content>
                                        <Card.Content description="Download the Apple application" />
                                    </Card>
                                </Card.Group>

                            </Grid.Row>
                            */}
            </Grid>
          </Segment>

        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  user_details: state.authReducer.user_details,
  cn: state.authReducer.cn,
  unique_id: state.authReducer.unique_id,
  user_id: state.authReducer.user_id,
  name: state.authReducer.name,
  user_groups: state.authReducer.user_groups,
  loading_hours: state.trHoursReducer.loading,
  hours: state.trHoursReducer.hours,
  tr_holidays: state.holidayReducer.tr_holidays,
  loading_tr: state.holidayReducer.loading_tr,
  abilities: state.authReducer.abilities,
  company_code: state.authReducer.company_code

});

const mapDispatchToProps = dispatch => {
  return {
    getHours: (cn_code, params) => dispatch(startGetTrHours(cn_code, params)),
    getHolidays: (params, userID) => dispatch(startGetTRHolidays(params, userID))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeContainer));
