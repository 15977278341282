import React from "react";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ExternalRoute from "./ExternalRoute";
import NotFoundContainer from "../containers/shared/NotFoundContainer";
import MainContainer from "../containers/shared/MainContainer";
import LoginContainer from "../containers/shared/LoginContainer";
import AuthContainer from "../containers/shared/AuthContainer";
import HomeContainer from "../containers/shared/HomeContainer";
import NotAuthorized from "../containers/shared/NotAuthorizedContainer";
import EmailSignatureContainer from "../containers/core/email-signature/EmailSignatureContainer";
import CutoffContainer from "../containers/core/administration/manage-cut-off/CutoffContainer";
import ManageCompaniesContainer from "../containers/core/administration/manage-companies/ManageCompaniesContainer";
import ManageTypesContainer from "../containers/core/administration/manage-types/ManageTypesContainer";
import CustomerCreationContainer from "../containers/core/customer-creation/CustomerCreationContainer";
import ProjectContainer from "../containers/core/project/ProjectContainer";
import OfferRequestAdminContainer from "../containers/core/administration/offer-request/OfferRequestAdminContainer";
import OfferRequestLogsContainer from "../containers/core/administration/offer-request/OfferRequestLogsContainer";
import CustomerCreationRequestAdminContainer from "../containers/core/administration/customer-creation/CustomerCreationRequestAdminContainer";
import OfferRequestContainer from "../containers/core/offer-request/OfferRequestContainer";
import TimeReportContainer from "../containers/core/timereport/TimeReportContainer";
import ReceiptsAdminContainer from "../containers/core/administration/receipts/ReceiptsAdminContainer";
import ReceiptsFromUserIdContainer from "../containers/core/administration/receipts/ReceiptsFromUserIdContainer";
import ReceiptFromIdContainer from "../containers/core/administration/receipts/ReceiptFromIdContainer";
import ProjectsAdminContainer from "../containers/core/administration/projects/ProjectsAdminContainer";
import ProjectsAdminDetails from "../containers/core/administration/projects/ProjectsAdminDetails";
import ManageConsultantsContainer from "../containers/core/administration/manage-consultants/ManageConsultantsContainer";
import ManageConsultantFromIdContainer from "../containers/core/administration/manage-consultants/ManageConsultantByUserIdContainer";
import ManageConsultantsByUniqueIdContainer from "../containers/core/administration/manage-consultants/ManageConsultantsByUniqueIdContainer";
import CorporateProjectsContainer from "../containers/core/project/CorporateProjectsContainer";
import ProjectApprovalContainer from "../containers/core/project/ProjectApprovalContainer";
import ReportsContainer from "../containers/core/reports/ReportsContainer";
import ManageAciCoefficientContainer from "../containers/core/administration/manage-aci-coefficients/ManageAciCoefficientContainer";
import ManageAciCoefficientUniqueContainer from "../containers/core/administration/manage-aci-coefficients/ManageAciCoefficientUniqueContainer";
import ProjectDetailsContainer from "../containers/core/project/ProjectDetailsContainer";
import ProjectSalesLevelContainer from "../containers/core/project/ProjectSalesLevelContainer";
import PfeContainer from "../containers/core/project-planning/PfeContainer";
import BudgetContainer from "../containers/core/project-planning/BudgetContainer";
import ApproverTableContainer from "../containers/core/administration/project-planning/ApproverTableContainer";
import PfeCreationContainer from "../containers/core/project-planning/PfeCreationContainer";
import ExternalRatesAdminContainer from "../containers/core/administration/project-planning/ExternalRatesAdminContainer";
import ExternalRatesByIdContainer from "../containers/core/administration/project-planning/ExternalRatesByIdContainer";
import ExternalRatesExceptionContainer from "../containers/core/administration/project-planning/ExternalRatesExceptionContainer";
import StandardCostAdminContainer from "../containers/core/administration/project-planning/StandardCostAdminContainer";
import BudgetByIdContainer from "../containers/core/project-planning/BudgetByIdContainer";
import InvoicesContainer from "../containers/core/invoices/InvoicesContainer";
import ProjectMilestoneForm from "../components/core/invoices/ProjectMilestoneForm";
import ApprovalProjectContainer from "../containers/core/approval/ApprovalProjectContainer";
import HierarchyManagementContainer from "../containers/core/administration/hierarchy-management/HierarchyManagementContainer";
import AdminHomeContainer from "../containers/core/administration/AdminHomeContainer";
import OfferRequestHomeContainer from "../containers/core/administration/offer-request/OfferRequestHomeContainer";
import ProjectPlanningAdminContainer from "../containers/core/administration/project-planning/ProjectPlanningAdminContainer";
import ProjectPlanningHomeContainer from "../containers/core/project-planning/ProjectPlanningHomeContainer";
import WipContainer from "../containers/core/wip/WipContainer";
import AdminHRReportsContainer from "../containers/core/administration/hr-reports/AdminHRReportsContainer";
import AdminOrderValueContainer from "../containers/core/administration/order-value/AdminOrderValueContainer";
import AdminOrderValueDetailsContainer from "../containers/core/administration/order-value/AdminOrderValueDetailsContainer";
import CvGeneratorContainer from "../containers/core/cvGenerator/CvGeneratorContainer";
import CvGeneratorContainerId from "../containers/core/cvGenerator/CvGeneratorContainerId";
import RolesManagementContainer from "../containers/core/administration/roles-management/RolesManagementContainer";
//import ReactPdfExample from "../containers/core/cvGenerator/ReactPdfExample";
import Vcard from "../containers/core/vcard/Vcard"
import HomePageContainer from "../containers/shared/HomePageContainer";
import ReactGA from 'react-ga';
import DailyProgressContainer from '../containers/core/daily-progress/DailyProgressContainer';
import DailyProgressProjectTable from "../containers/core/daily-progress/DailyProgressProjectTable";
// Next
import BudgetNextContainer from "../containers/core/project-planning/BudgetNextContainer";
import PfeNextContainer from "../containers/core/project-planning/PfeNextContainer";
import BudgetNextByIdContainer from "../containers/core/project-planning/BudgetNextByIdContainer";

import PfeNextEditContainer from "../containers/core/project-planning/PfeNextEditContainer";
import PfeNextCreationContainer from "../containers/core/project-planning/PfeNextCreationContainer";
import BudgetT0NextEditContainer from "../containers/core/project-planning/BudgetT0NextEditContainer";
import BudgetT2NextEditContainer from "../containers/core/project-planning/BudgetT2NextEditContainer";


export const history = createHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const AppRouter = props => {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <PublicRoute path="/google" component={AuthContainer} exact={true} />
          <PublicRoute path="/" component={MainContainer} exact={true} />
          <PublicRoute path="/login" component={LoginContainer} exact={true} />
          <Route path='/vCard/:id' component={() => <Vcard />} />
          <PrivateRoute
            path="/not_authorized"
            component={NotAuthorized}
            exact={true}
            activeIndex={-1}
            className="NotAuthorized"
          />
          <PrivateRoute
            path="/homePage"
            component={props.isAuthorized ? HomePageContainer : NotAuthorized}
            exact={true}
            activeIndex={0}
            className="Home"
          />
          <PrivateRoute
            path="/home"
            component={props.isAuthorized ? HomeContainer : NotAuthorized}
            exact={true}
            activeIndex={0}
            className="Home"
          />

          <PrivateRoute
            path="/signature"
            component={EmailSignatureContainer}
            exact={true}
            activeIndex={1}
            className="Signature"
          />
          <PrivateRoute
            path="/administration"
            component={AdminHomeContainer}
            exact={true}
            activeIndex={2}
            className="Admin"
          />
          <PrivateRoute
            path="/admin/cutoff"
            component={CutoffContainer}
            exact={true}
            activeIndex={2}
            className="Admin Cutoff"
          />
          <PrivateRoute
            path="/admin/manage-companies"
            component={ManageCompaniesContainer}
            exact={true}
            activeIndex={20}
            className="Admin Companies"
          />
          <PrivateRoute
            path="/admin/manage-types"
            component={ManageTypesContainer}
            exact={true}
            activeIndex={21}
            className="Admin Types"
          />
          {/* <PrivateRoute
            path="/admin/customer-creation-request-admin-values"
            component={CustomerCreationRequestAdminContainer}
            exact={true}
            activeIndex={22}
            className="Admin Customer"
          /> */}

          {/*<PrivateRoute
            path="/admin/pdf-example"
            component={ReactPdfExample}
            exact={true}
            activeIndex={123}
            className="PDF EXAMPLE"
          />*/}

          <PrivateRoute
            path="/admin/receipts"
            component={ReceiptsAdminContainer}
            exact={true}
            activeIndex={23}
            className="Admin Receipt"
          />
          <PrivateRoute
            path="/admin/receipts/reports/:report_id/expense/:expense_id"
            component={ReceiptFromIdContainer}
            exact={true}
            activeIndex={23}
            className="Admin Expense"
          />
          <PrivateRoute
            path="/admin/receipts/reports/:report_id"
            component={ReceiptsFromUserIdContainer}
            exact={true}
            activeIndex={23}
            className="Admin Reports"
          />
          <PrivateRoute
            path="/admin/projects"
            component={ProjectsAdminContainer}
            exact={true}
            activeIndex={24}
            className="Admin Projects"
          />
          <PrivateRoute
            path="/admin/projects/:project_id"
            component={ProjectsAdminDetails}
            exact={true}
            activeIndex={24}
            className="Admin Projects Details"
          />
          <PrivateRoute
            path="/admin/offer-request"
            component={OfferRequestHomeContainer}
            exact={true}
            activeIndex={200}
            className="Admin OfferRequest"
          />
          <PrivateRoute
            path="/admin/offer-request/offer-request-admin-values"
            component={OfferRequestAdminContainer}
            exact={true}
            activeIndex={200}
            className="Admin OfferRequest Values"
          />
          <PrivateRoute
            path="/admin/offer-request/offer-request-admin-logs"
            component={OfferRequestLogsContainer}
            exact={true}
            activeIndex={201}
            className="Admin OfferRequest Logs"
          />
          <PrivateRoute
            path="/admin/consultants"
            component={ManageConsultantsContainer}
            exact={true}
            activeIndex={202}
            className="Admin Consultants"
          />
          <PrivateRoute
            path="/admin/consultants/:unique_id"
            component={ManageConsultantsByUniqueIdContainer}
            exact={true}
            activeIndex={203}
            className="Admin Consultant"
          />
          <PrivateRoute
            path="/admin/consultants/details/:consultant_id"
            component={ManageConsultantFromIdContainer}
            exact={true}
            activeIndex={204}
            className="Admin ConsultantDetails"
          />
          <PrivateRoute
            path="/admin/project-planning"
            component={ProjectPlanningAdminContainer}
            exact={true}
            activeIndex={303}
            className="Admin Project Planning"
          />
          <PrivateRoute
            path="/admin/project-planning/external-rates"
            component={ExternalRatesAdminContainer}
            exact={true}
            activeIndex={304}
            className="Admin ExternalRates"
          />
          <PrivateRoute
            path="/admin/project-planning/external-rates/:unique_id"
            component={ExternalRatesByIdContainer}
            exact={true}
            activeIndex={305}
            className="Admin ExternalRate"
          />
          <PrivateRoute
            path="/admin/project-planning/external-rates/:unique_id/:dummy/details"
            component={ExternalRatesExceptionContainer}
            exact={true}
            activeIndex={306}
            className="Admin ExternalRateDetails"
          />
          <PrivateRoute
            path="/admin/project-planning/standard-costs"
            component={StandardCostAdminContainer}
            exact={true}
            activeIndex={307}
            className="Admin StandardCosts"
          />
          {/* <PrivateRoute
            path="/admin/project-planning/approvers-management"
            component={ApproverTableContainer}
            exact={true}
            activeIndex={308}
            className="Admin ApproversMgmt"
          /> */}
          {/* Non visibile nel menu */}
          {/* <PrivateRoute
            path="/admin/hierarchy-management"
            component={HierarchyManagementContainer}
            exact={true}
            activeIndex={25}
            className="Admin Hierarchy"
          /> */}
          <PrivateRoute
            path="/admin/hr-reports"
            component={AdminHRReportsContainer}
            exact={true}
            activeIndex={26}
            className="Admin HR Reports"
          />
          <PrivateRoute
            path="/admin/order-value"
            component={AdminOrderValueContainer}
            exact={true}
            activeIndex={27}
            className="Admin Order Value"
          />
          <PrivateRoute
            path="/admin/order-value/:project_id"
            component={AdminOrderValueDetailsContainer}
            exact={true}
            activeIndex={28}
            className="Admin Order Value Details"
          />
          <PrivateRoute
            path="/customer"
            component={CustomerCreationContainer}
            exact={true}
            activeIndex={4}
            className="Customer"
          />
          <PrivateRoute
            path="/wip"
            component={WipContainer}
            exact={true}
            activeIndex={13}
            className="Wip"
          />
          <PrivateRoute
            path="/cvGenerator"
            component={CvGeneratorContainer}
            exact={true}
            activeIndex={14}
            className="CV Generator"
          />
          <PrivateRoute
            path="/cvGenerator/:id"
            component={CvGeneratorContainerId}
            exact={true}
            activeIndex={14}
            className="CV Generator"
          />
          <PrivateRoute
            path="/project"
            component={ProjectContainer}
            exact={true}
            activeIndex={5}
            className="Project"
          />
          <PrivateRoute
            path="/project/approval/"
            component={ProjectApprovalContainer}
            exact={true}
            activeIndex={5}
            className="Project Approval"
          />
          <PrivateRoute
            path="/project/approval/:project_id"
            component={ProjectDetailsContainer}
            exact={true}
            activeIndex={5}
            className="Project ApprovalDetails"
          />
          <PrivateRoute
            path="/project/corporate/"
            component={CorporateProjectsContainer}
            exact={true}
            activeIndex={5}
            className="Project Corporate"
          />
          <PrivateRoute
            path="/project/corporate/:project_id"
            component={CorporateProjectsContainer}
            exact={true}
            activeIndex={5}
            className="Project CorporateDetails"
          />
          <PrivateRoute
            path="/project/:project_id"
            component={ProjectDetailsContainer}
            exact={true}
            activeIndex={5}
            className="Project Details"
          />
          <PrivateRoute
            path="/project/:project_id/sales_level/:sales_level_id"
            component={ProjectSalesLevelContainer}
            exact={true}
            activeIndex={5}
            className="Project SalesLevel"
          />
          <PrivateRoute
            path="/offer-request"
            component={OfferRequestContainer}
            exact={true}
            activeIndex={6}
            className="OfferRequest"
          />
          <PrivateRoute
            path="/time-report"
            component={TimeReportContainer}
            exact={true}
            activeIndex={7}
            className="Timereport"
          />
          <PrivateRoute
            path="/reports"
            component={ReportsContainer}
            exact={true}
            activeIndex={8}
            className="Reports"
          />
          <PrivateRoute
            path="/admin/roles-management"
            component={RolesManagementContainer}
            exact={true}
            activeIndex={13}
            className="RolesManagement"
          />

          <PrivateRoute
            path="/admin/aci"
            component={ManageAciCoefficientContainer}
            exact={true}
            activeIndex={9}
            className="Admin Aci"
          />
          <PrivateRoute
            path="/admin/aci/:consultant_id"
            component={ManageAciCoefficientUniqueContainer}
            exact={true}
            activeIndex={9}
            className="Admin Aci"
          />
          <PrivateRoute
            path="/project-planning/"
            component={ProjectPlanningHomeContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning"
          />
          <PrivateRoute
            path="/project-planning/PFE"
            component={PfeContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning Pfe"
          />
          <PrivateRoute
            path="/project-planning/PFE/new"
            component={PfeCreationContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning PfeNew"
          />
          <PrivateRoute
            path="/project-planning/budget"
            component={BudgetContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning Budget"
          />
          <PrivateRoute
            path="/project-planning/budget/:budget_id"
            component={BudgetByIdContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning BudgetDetails"
          />
          <PrivateRoute
            path="/invoices"
            component={InvoicesContainer}
            exact={true}
            activeIndex={11}
            className="Invoices"
          />
          <PrivateRoute
            path="/invoices/:project_id"
            component={ProjectMilestoneForm}
            exact={true}
            activeIndex={11}
            className="Invoices Details"
          />
          <PrivateRoute
            path="/approval"
            component={ApprovalProjectContainer}
            exact={true}
            activeIndex={12}
            className="Approval"
          />
          <PrivateRoute
            path="/daily-progress"
            component={DailyProgressContainer}
            exact={true}
            activeIndex={12}
            className="Approval"
          />
          <PrivateRoute
            path="/daily-progress/budgetsDaily/:budget_id"
            component={DailyProgressProjectTable}
            exact={true}
            activeIndex={12}
            className="Approval"
          />
          
          {/* NEXT  */}
          <PrivateRoute
            path="/project-planning/budget_next"
            component={BudgetNextContainer}
            exact={true}
            activeIndex={12}
            className="Approval"
          />
          <PrivateRoute
            path="/project-planning/PFE_next"
            component={PfeNextContainer}
            exact={true}
            activeIndex={12}
            className="Approval"
          />
          <PrivateRoute
            path="/project-planning/PFE_next/new"
            component={PfeNextCreationContainer}
            exact={true}
            activeIndex={12}
            className="Approval"
          />
          <PrivateRoute
            path="/project-planning/PFE_next/:pfe_id"
            component={PfeNextEditContainer}
            exact={true}
            activeIndex={12}
            className="Approval"
          />
          <PrivateRoute
            path="/project-planning/budget_next/:budget_id"
            component={BudgetNextByIdContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning BudgetDetails"
          />
          <PrivateRoute
            path="/project-planning/budget_next/:budget_id/t0"
            component={BudgetT0NextEditContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning BudgetDetails"
          />
          <PrivateRoute
            path="/project-planning/budget_next/:budget_id/t2"
            component={BudgetT2NextEditContainer}
            exact={true}
            activeIndex={10}
            className="ProjectPlanning BudgetDetails"
          />
          {/* NEXT  */}


          <ExternalRoute
            path='/help/login'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/time-report'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/project'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/reports'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/project-planning/PFE'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/project-planning/budget'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/invoices'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/wip'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/help/project/approval/'
            exact={true}
            link='/avvale-support'
          />
          <ExternalRoute
            path='/avvale-support/'
            exact={true}
            link='https://avvale-support.atlassian.net/servicedesk/customer/portals'
          />
          <Route component={NotFoundContainer} />
        </Switch>
      </div>
    </Router>
  );
};

const mapStateToProps = state => ({
  isUserAuthenticated: state.authReducer.isUserAuthenticated,
  isAuthorized: state.authReducer.isAuthorized,
  user_groups: state.authReducer.user_groups,
});

export default connect(mapStateToProps)(AppRouter);
