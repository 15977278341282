import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Icon,
  Label,
  Loader,
  Modal,
  Segment,
  Table
} from "semantic-ui-react";
import IconButton from "../../../../components/shared/buttons/IconButton";
import PaginatedReusableTable from '../../../../components/shared/PaginatedReusableTable';
import { history } from "../../../../routers/AppRouters";
import {
  startCreateOrderForVersion,
  startDeleteOrderForVersion,
  startEditNoteForProject,
  startEditOrderForVersion,
  startGetProjectById,
  startResetPage
} from "../../../../store/actions/core/administration/order_value";
import constants from "../../../../utils/constants";
import { formatNumber } from "../../../../utils/functionUtils";
import { capitalize } from "../../../../utils/stringUtils";

const VersionColumns = [
  {
    Header: 'PFE Approve Date',
    accessor: 'pfe_approval_date',
    id: 'pfe_approval_date'
  },
  {
    Header: 'Project Code',
    accessor: 'project_code',
    id: 'project_code'
  },
  {
    Header: 'Project Manager',
    accessor: 'project_manager',
    id: 'project_manager'
  },
  {
    Header: 'TEP Ord Val PFE',
    accessor: 'tep_order_value_pfe',
    id: 'tep_order_value_pfe',
    Cell: row => {
      if (row.value === undefined) {
        return null;
      }
      return formatNumber(row.value);
    }
  },
  {
    Header: 'Revenues cumulated',
    accessor: 'cumulative_revenues',
    id: 'cumulative_revenues',
    Cell: row => {
      if (row.value === undefined) {
        return null;
      }
      return formatNumber(row.value);
    }
  },
  {
    Header: 'Delta Rev. closure',
    accessor: 'delta_revenues_closure',
    id: 'delta_revenues_closure',
    Cell: row => {
      if (row.value === undefined) {
        return null;
      }
      return formatNumber(row.value);
    }
  },
  {
    Header: 'Offer Value',
    accessor: 'offer_value',
    id: 'offer_value',
    Cell: row => {
      if (row.value === undefined) {
        return null;
      }
      return formatNumber(row.value);
    }
  },
  {
    Header: 'DOCS Order Val.',
    accessor: 'docs_order_value',
    id: 'docs_order_value',
    Cell: row => {
      if (row.value === undefined) {
        return null;
      }
      return formatNumber(row.value);
    }
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    id: 'notes',
    Cell: row => {
      return unescape(row.value);
    }
  },
  {
    Header: 'Offer Code',
    accessor: 'offer_code',
    id: 'offer_code',
    Cell: row => {
      return unescape(row.value);
    }
  },

];

const OfferValuesColumns = [
  {
    Header: 'Offer Value',
    accessor: 'offer_value',
    id: 'offer_value',
    Cell: row => {
      return formatNumber(row.value);
    }
  },
  {
    Header: 'Order Values (DOCS)',
    accessor: 'docs_order_value',
    id: 'docs_order_value',
    Cell: row => {
      return formatNumber(row.value);
    }
  },
  {
    Header: 'Order Number',
    accessor: 'order_number',
    id: 'order_number',
  },
  {
    Header: 'Competence - From',
    accessor: 'competence_start_date',
    id: 'competence_start_date',
  },
  {
    Header: 'Competence - To',
    accessor: 'competence_end_date',
    id: 'competence_end_date',
  },
  {
    Header: 'Notes',
    accessor: 'offer_notes',
    id: 'offer_notes',
    Cell: row => {
      return unescape(row.value);
    }
  },

];

export class AdminOrderValueDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openOrderModal: false,
      openNoteModal: false,
      selectedVersionRow: {},
      project_id: undefined,
      orderNote: "",
      orderValue: {
        docs_order_value: 0,
        id: "",
        offer_value: 0,
        order_number: "",
        pfe_id: "",
        version: "",
        competence: {
          from: moment(),
          to: moment()
        },

        insertOrUpdate: "insert",
      }
    };
  }

  componentWillMount() {
    //only on first loading i'll be here
    this.props.resetPage();
    this.props.getProjectById(this.props.match.params.project_id);
  };

  componentWillReceiveProps(nextProps) {
    //i need to check if i'm changing page
    if (this.state.project_id !== nextProps.match.params.project_id) {
      this.props.resetPage();
      this.props.getProjectById(this.props.match.params.project_id);
    }

    this.setState(prevState => ({
      ...prevState,
      selectedVersionRow: this.props.order_value ? this.props.order_value[0] : {},
      project_id: this.props.match.params.project_id ? this.props.match.params.project_id : undefined,
    }));

  }

  componentDidMount() {
  }

  addNewOrder = () => {
    this.setState(prevState => ({
      ...this.prevState,
      openOrderModal: true,
      openNoteModal: false,
      orderNote: "",
      orderValue: {
        docs_order_value: 0,
        id: "",
        offer_value: 0,
        order_number: "",
        offer_notes: "",
        pfe_id: "",
        version: "",
        competence: {
          from: moment(),
          to: moment()
        },
        insertOrUpdate: "insert",
      }
    }));
  }

  openEditNoteModal = () => {
    this.setState(prevState => ({
      ...this.prevState,
      openOrderModal: false,
      openNoteModal: true,
      orderNote: this.state.selectedVersionRow.notes
    }));
  }

  onCloseEditNoteModal = () => {
    this.setState(prevState => ({
      ...this.prevState,
      openOrderModal: false,
      openNoteModal: false,
      orderNote: this.state.selectedVersionRow.notes
    }));
  }

  editProjectNote = () => {

    const projectId = this.state.project_id;
    let orderNote = this.state.orderNote;

    this.onCloseEditNoteModal();

    this.props.editProjectNote(projectId, orderNote);
  }

  onSelectVersionRow = (selectedRow) => {
    //console.log(selectedRow);
    this.setState(prevState => ({
      ...prevState,
      selectedVersionRow: selectedRow
    }));
  }

  onSelectOfferValueRow = (selectedRow) => {
    //console.log(selectedRow);
    this.setState(prevState => ({
      ...prevState,
      openOrderModal: true,
      orderValue: {
        docs_order_value: selectedRow.docs_order_value,
        id: selectedRow.id,
        offer_value: selectedRow.offer_value,
        order_number: selectedRow.order_number,
        offer_notes: selectedRow.offer_notes,
        pfe_id: selectedRow.pfe_id,
        version: selectedRow.version,
        competence: {
          from: selectedRow.competence_start_date ? moment(selectedRow.competence_start_date) : moment(),
          to: selectedRow.competence_end_date ? moment(selectedRow.competence_end_date) : moment(),
        },
        insertOrUpdate: "update",
      }
    }));
  }

  onCloseOrderValueModal = () => {
    this.setState(prevState => ({
      ...prevState,
      openOrderModal: false,
      orderValue: {
        docs_order_value: 0,
        id: "",
        offer_value: 0,
        order_number: "",
        offer_notes: "",
        pfe_id: "",
        version: "",
        competence: {
          from: moment(),
          to: moment(),
        },
        insertOrUpdate: "insert",
      }
    }));
  };

  handleChange = (e, data) => {
    this.setState(prevState => ({
      ...prevState,
      [data.name]: data.value
    }));
  }

  handleOrderValueChange = (e, data) => {
    this.setState(prevState => ({
      ...prevState,
      orderValue: {
        ...prevState.orderValue,
        [data.name]: data.value
      }
    }));
  }

  handleTextArea = (e, data) => {
    console.log("event", e);
    console.log("data", data);
  }

  insertOrUpdateOrder = () => {
    let order = this.state.orderValue;
    const projectId = this.state.project_id;

    //console.log(order);

    if (_.isEmpty(order.order_number) && (order.offer_value === '' || order.offer_value === 0) && (order.docs_order_value === '' || order.docs_order_value === 0)) {
      toast(
        {
          title: "Order Value",
          description: "At least one parameter must be populated",
          icon: "x",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
      return;
    }


    this.onCloseOrderValueModal();

    switch (order.insertOrUpdate) {
      case 'insert':
        this.props.createOrderForVersion(projectId, order);
        break;
      case 'update':
        this.props.editOrderForVersion(projectId, order);
        break;
      default:
        break;
    }
  }

  deleteOrder = () => {
    let order = this.state.orderValue;
    const projectId = this.state.project_id;

    this.onCloseOrderValueModal();

    this.props.deleteOrderForVersion(projectId, order);
  }

  onDatesChangeCompetence = ({ startDate, endDate }) => {
    this.setState((prevState) => ({
      ...prevState,
      orderValue: {
        ...prevState.orderValue,
        competence: {
          from: moment(startDate),
          to: moment(endDate),
        },
      },
    }));
  }


  render() {

    return (
      <Container>
        <Header>Order Value Details</Header>
        <Dimmer active={this.props.loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Segment clearing>

          <Modal
            size="small"
            dimmer="inverted"
            open={this.state.openOrderModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={this.onCloseOrderValueModal}
            closeIcon
          >
            <Modal.Header>
              {capitalize(this.state.orderValue.insertOrUpdate)} Order
            </Modal.Header>
            <Modal.Content scrolling={true}>
              <Form>
                <Form.Input
                  label="Offer Value"
                  name="offer_value"
                  onChange={this.handleOrderValueChange}
                  type="number"
                  min={0}
                  fluid
                  //required
                  value={this.state.orderValue.offer_value}
                />
                <Form.Input
                  label="Order Values (DOCS)"
                  name="docs_order_value"
                  onChange={this.handleOrderValueChange}
                  type="number"
                  min={0}
                  fluid
                  //required
                  value={this.state.orderValue.docs_order_value}
                />
                <Form.Input
                  label="# Order"
                  name="order_number"
                  onChange={this.handleOrderValueChange}
                  type="text"
                  fluid
                  //required
                  value={this.state.orderValue.order_number}
                //readOnly={this.state.orderValue.insertOrUpdate == 'update'}
                //disabled={this.state.orderValue.insertOrUpdate == 'update'}
                />

                <Form.Field>
                  <label>Competence</label>
                  <DateRangePicker
                    startDateId="competence_from"
                    endDateId="competence_to"
                    startDatePlaceholderText="From"
                    endDatePlaceholderText="To"
                    startDate={this.state.orderValue.competence.from !== "" ? moment(this.state.orderValue.competence.from) : moment()}
                    endDate={this.state.orderValue.competence.to !== "" ? moment(this.state.orderValue.competence.to) : moment()}

                    onDatesChange={this.onDatesChangeCompetence}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => {
                      this.setState({ focusedInput });
                    }}
                    isOutsideRange={() => false}
                    showDefaultInputIcon={true}
                    inputIconPosition="after"
                    small={true}
                    keepOpenOnDateSelect={true}
                    reopenPickerOnClearDates={true}
                    hideKeyboardShortcutsPanel={true}
                    initialVisibleMonth={() => moment().subtract(1, "month")}
                    minimumNights={0}
                  />
                </Form.Field>

                <Form.TextArea
                  label="Note"
                  name="offer_notes"
                  onInput={this.handleOrderValueChange}
                  value={this.state.orderValue.offer_notes ? unescape(this.state.orderValue.offer_notes) : ""}
                />
              </Form>
            </Modal.Content>

            <Modal.Actions>
              {this.state.selectedVersionRow.latest && this.state.orderValue.insertOrUpdate === 'update' ?
                <Button
                  floated="right"
                  content={"Delete"}
                  negative
                  onClick={this.deleteOrder}
                />
                : null}
              {this.state.selectedVersionRow.latest ?
                <Button
                  floated="right"
                  content={capitalize(this.state.orderValue.insertOrUpdate)}
                  onClick={this.insertOrUpdateOrder}
                />
                : null}
            </Modal.Actions>
          </Modal>

          <Modal
            size="small"
            dimmer="inverted"
            open={this.state.openNoteModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={this.onCloseEditNoteModal}
            closeIcon
          >
            <Modal.Header>
              Edit Version Note
            </Modal.Header>
            <Modal.Content scrolling={true}>
              <Form>
                <Form.TextArea
                  label="Note"
                  name="orderNote"
                  onInput={this.handleChange}
                  value={unescape(this.state.orderNote)}
                />
              </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button
                floated="right"
                content="Update"
                onClick={this.editProjectNote}
              />
            </Modal.Actions>
          </Modal>

          {this.props.order_value && this.props.order_value[0].order_status ?
            (
              <div>
                <Header floated='left'>
                  <Label color={this.props.order_value[0].order_status === "OK" ? "teal" : (this.props.order_value[0].order_status === "NA" ? "yellow" : "red")} ribbon>
                    {this.props.order_value[0].order_status}
                  </Label>
                </Header>

                <Button icon className="cancel" floated='right' onClick={() => history.goBack()}>
                  <Icon name="arrow left" />
                  Back
                </Button>
              </div>
            )
            : (
              <div>
                <Button icon className="cancel" floated='right' onClick={() => history.goBack()}>
                  <Icon name="arrow left" />
                  Back
                </Button>
              </div>
            )}

          <div>

            <Table celled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Project Code</Table.Cell>
                  <Table.Cell active>{this.props.project ? this.props.project.project_code : ""}</Table.Cell>
                  <Table.Cell>Billing Client</Table.Cell>
                  <Table.Cell active>{this.props.project ? this.props.project.billing_client : ""}</Table.Cell>
                  <Table.Cell>Project Manager</Table.Cell>
                  <Table.Cell active>{this.props.project ? this.props.project.project_manager : ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Invoice Type</Table.Cell>
                  <Table.Cell active>{this.props.project ? this.props.project.invoice_type : ""}</Table.Cell>
                  <Table.Cell>Currency</Table.Cell>
                  <Table.Cell active>{this.props.project ? this.props.project.currency : ""}</Table.Cell>
                  <Table.Cell>Description</Table.Cell>
                  <Table.Cell active>{this.props.project ? this.props.project.description : ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Activity Type</Table.Cell>
                  <Table.Cell active>{this.props.project ? this.props.project.activity_type : ""}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          <div style={{ marginTop: "30px", marginBottom: "30px", borderTop: "1px solid" }}>
          </div>

          <div>
            <Header floated='left'>
              Versions
            </Header>
            <PaginatedReusableTable
              manual={false}
              filterable={false}
              sortable={false}
              loading={this.props.loading}
              columns={VersionColumns}
              data={this.props.order_value}
              onClick={this.onSelectVersionRow}
              pageSize={this.props.order_value ? this.props.order_value.length : 1}
              showPagination={false}
            />
          </div>

          <div style={{ marginTop: "30px", marginBottom: "30px", borderTop: "1px solid" }}>
          </div>

          {this.state.selectedVersionRow ? (
            <div>
              <Header floated='left'>
                Details
              </Header>

              {this.state.selectedVersionRow.latest ?
                (<IconButton onClick={this.addNewOrder}
                  floated="right"
                  icon="plus"
                  label="Add ODA OFFER NOTE" />
                )
                : null}

              {this.state.selectedVersionRow.latest ?
                (<IconButton onClick={this.openEditNoteModal}
                  floated="right"
                  icon="edit"
                  label="Edit Version Note" />
                )
                : null}

              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>PFE approve date</Table.Cell>
                    <Table.Cell active>{this.state.selectedVersionRow.pfe_approval_date}</Table.Cell>
                    <Table.Cell>Start/End Date</Table.Cell>
                    <Table.Cell active>{this.state.selectedVersionRow.pfe_start_date} / {this.state.selectedVersionRow.pfe_end_date}</Table.Cell>
                    <Table.Cell>Note</Table.Cell>
                    <Table.Cell active onClick={this.handleNotes}>{this.state.selectedVersionRow.notes ? unescape(this.state.selectedVersionRow.notes) : ""}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>TEP Order Value PFE</Table.Cell>
                    <Table.Cell>{formatNumber(this.state.selectedVersionRow.tep_order_value_pfe)}</Table.Cell>
                    <Table.Cell>Offer Value</Table.Cell>
                    <Table.Cell>{formatNumber(this.state.selectedVersionRow.offer_value)}</Table.Cell>
                    <Table.Cell>Order Value DOCS</Table.Cell>
                    <Table.Cell>{formatNumber(this.state.selectedVersionRow.docs_order_value)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Revenues cumulated</Table.Cell>
                    <Table.Cell active>{formatNumber(this.state.selectedVersionRow.cumulative_revenues)}</Table.Cell>
                    <Table.Cell>Delta closure</Table.Cell>
                    <Table.Cell active>{formatNumber(this.state.selectedVersionRow.delta_revenues_closure)}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell active></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PFE Link</Table.Cell>
                    <Table.Cell>
                      {this.state.selectedVersionRow.pfe_link
                        ? (<a href={this.state.selectedVersionRow.pfe_link} target="_blank" rel="noopener noreferrer">PFE</a>)
                        : (this.state.selectedVersionRow.pfe_id && this.state.selectedVersionRow.pfe_link === null
                          ? <a href={`/project-planning/PFE_next/${this.state.selectedVersionRow.pfe_id}`} target="_blank" rel="noopener noreferrer">PFE</a>
                          : <></>
                        )
                      }
                    </Table.Cell>
                    <Table.Cell>Budget Link</Table.Cell>
                    <Table.Cell>
                      {this.state.selectedVersionRow.budget_link
                        ? (<a href={this.state.selectedVersionRow.budget_link} target="_blank" rel="noopener noreferrer">BUDGET</a>)
                        : (this.state.selectedVersionRow.budget_id && this.state.selectedVersionRow.budget_link === null
                          ? <a href={`/project-planning/budget_next/${this.state.selectedVersionRow.budget_id}`} target="_blank" rel="noopener noreferrer">BUDGET</a>
                          : <></>
                        )
                      }
                    </Table.Cell>
                    <Table.Cell>Drive Link</Table.Cell>
                    <Table.Cell>
                      {this.state.selectedVersionRow.drive
                        ? <a href={this.state.selectedVersionRow.drive} target="_blank" rel="noopener noreferrer">DRIVE</a>
                        : <></>
                      }
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              {this.state.selectedVersionRow.details ? (
                <PaginatedReusableTable
                  manual={false}
                  filterable={false}
                  sortable={false}
                  loading={this.props.loading}
                  columns={OfferValuesColumns}
                  data={this.state.selectedVersionRow.details}
                  onClick={this.onSelectOfferValueRow}
                  pageSize={this.state.selectedVersionRow.details ? this.state.selectedVersionRow.details.length : 1}
                  showPagination={false}
                />
              ) : null}

            </div>

          ) : null}

          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
          </div>


        </Segment>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return ({
    loading: state.orderValueReducer.loading,
    project: state.orderValueReducer.project,
    order_value: state.orderValueReducer.order_value,
  })
};

function mapDispatchToProps(dispatch) {
  return {
    getProjectById: (projectId) => dispatch(startGetProjectById(projectId)),
    createOrderForVersion: (projectId, orderData) => dispatch(startCreateOrderForVersion(projectId, orderData)),
    editOrderForVersion: (projectId, orderData) => dispatch(startEditOrderForVersion(projectId, orderData)),
    deleteOrderForVersion: (projectId, orderData) => dispatch(startDeleteOrderForVersion(projectId, orderData)),
    editProjectNote: (projectId, note) => dispatch(startEditNoteForProject(projectId, note)),
    resetPage: () => dispatch(startResetPage()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminOrderValueDetailsContainer);