import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Dropdown, Header, Icon, Image, Label, Menu, Popup, Responsive } from "semantic-ui-react";
import ReusableSidebar from "../../components/shared/ReusableSidebar";
import { startLogout } from "../../store/actions/shared/auth";
import { getTitleDecorator } from "../../utils/functionUtils";
require("moment/locale/en-gb");

class NavigationbarDesktop extends React.Component {
  constructor(props) {
    super(props);
    var menuState = window.localStorage.getItem("TEP_menu");

    if (menuState === "navslide_closed") {
      this.state = {
        isOpen: false,
        visible: "visibile",
        sideBarClasses: "very thin icon",
        marginLeft: "marginlefting",
        marginRight: "marginrighting",
        navfixed: "",
        mobileMenu: true,
        activeIndex: props.activeIndex,
        hover: false
      };
    } else {
      this.state = {
        isOpen: false,
        visible: "visibile",
        sideBarClasses: "",
        marginLeft: "",
        marginRight: "",
        navfixed: "navslide open",
        mobileMenu: false,
        activeIndex: props.activeIndex
      };
    }
  }

  toggleVisibility = () => {
    if (this.state.sideBarClasses !== "") {
      window.localStorage.setItem("TEP_menu", "navslide_open");
      this.setState({
        visible: "visibile",
        sideBarClasses: "",
        marginLeft: "",
        marginRight: "",
        navfixed: "navslide open",
        mobileMenu: false
      });
    } else {
      window.localStorage.setItem("TEP_menu", "navslide_closed");
      this.setState({
        visible: "visibile",
        sideBarClasses: "very thin icon ",
        marginLeft: "marginlefting",
        marginRight: "marginrighting",
        navfixed: "",
        mobileMenu: true
      });
    }
  };

  hoverOn = () => {
    this.setState({ hover: true });
  };
  hoverOff = () => {
    this.setState({ hover: false });
  };

  render() {
    const {
      visible,
      sideBarClasses,
      marginLeft,
      navfixed,
      mobileMenu,
    } = this.state;

    let username = "";
    if (this.props.auth && this.props.auth.user) {
      username = this.props.auth.user.attributes.name;
    }

    return (
      <Responsive minWidth={550}>
        <ReusableSidebar
          visible={visible}
          classes={sideBarClasses}
          showMobileMenu={mobileMenu}
          activeIndex={this.state.activeIndex}
          logoSidebar={true}
          loading={this.props.loading}
        />
        <div className="pusher pushable">
          <div className="pusher">
            <div className={"nav-bar navwrap"}>
              <Menu
                style={{ borderRadius: 0, "background": '#f97743' }}
                className={
                  "navslide inverted " +
                  marginLeft
                }>
                <div
                  id="nav-icon1"
                  className={"item " + navfixed}
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleVisibility}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="item ">
                  <NavLink to="/">
                    <Header
                      as="h2"
                      inverted
                      style={{ color: "white", fontSize: "1.5em", fontWeight: "700" }}>
                      Avvale Enterprise Portal {getTitleDecorator(window.location.href)}
                    </Header>
                  </NavLink>
                </div>
                <Menu.Menu position='right'>
                  <Link to={"/avvale-support"} target="_blank" style={{ textAlign: "right", padding: "1.2rem" }} >
                    <Popup
                      content={"Need help? Click here."}
                      trigger={(
                        <Label style={{ marginBottom: "0" }} color="teal" circular>
                          HELP
                        </Label>
                      )}
                    />
                  </Link>

                  <Dropdown
                    item
                    trigger={(<span>{username}</span>)}
                    pointing='top right'
                    icon={null}
                    style={{ padding: "1.2rem" }}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.props.logout}><Icon name="sign out" />Sign out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Menu>
              </Menu>
            </div>

            <div className={"body mainWrap navslide " + marginLeft}>
              {/*<PopupMessage/> */}
              {this.props.children}
            </div>
          </div>
        </div>
      </Responsive>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { auth: state.authReducer };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(startLogout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationbarDesktop);
